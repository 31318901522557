import styled from "styled-components";

export const Container = styled.button`
    position: relative;
    p {
        font-family: "Zen Kaku Gothic New" !important;
        font-weight: 900;
    }

    &.btn-opne-link {
        border-radius: 4px;
        background: #f7941d;

        height: 39px;
        position: static;
        transform: translateY(16px);
        margin: 0px;
        margin-bottom: 16px;
        padding: 0 20px;
        h4 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0px !important;
            &.title-link-sem-valor {
                font-size: 12px;
            }
        }
    }
`;
